/*

    VARIABLES   

*/

@custom-media --breakpoint-not-small screen and (min-width: 48em);
@custom-media --breakpoint-medium screen and (min-width: 48em) and (max-width: 60em);
@custom-media --breakpoint-large screen and (min-width: 60em);

.rb-app {
  --sans-serif: -apple-system, BlinkMacSystemFont, -system-ui, 'avenir next', avenir, helvetica, 'helvetica neue',
    ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
  --serif: athelas, serif;
  --monospace: SFMono, consolas, monaco, monospace;

  --font-size-5: 16px;
  --font-size-headline: calc(6 * var(--font-size-5));
  --font-size-subheadline: calc(5 * var(--font-size-5));
  --font-size-1: calc(2.64 * var(--font-size-5));
  --font-size-2: calc(2 * var(--font-size-5));
  --font-size-3: calc(1.52 * var(--font-size-5));
  --font-size-4: calc(1.15 * var(--font-size-5));
  --font-size-6: calc(0.87 * var(--font-size-5));
  --font-size-7: calc(0.75 * var(--font-size-5));

  --measure: 48em;
  --measure-narrow: 20em;
  --measure-wide: 34em;

  --letter-spacing-tight: -0.05em;
  --letter-spacing-1: 0.1em;
  --letter-spacing-2: 0.25em;

  --line-height-solid: 1;
  --line-height-title: 1.25;
  --line-height-copy: 1.5;

  --spacing-medium: 16px;
  --spacing-extra-small: calc(0.25 * var(--spacing-medium));
  --spacing-small: calc(0.5 * var(--spacing-medium));
  --spacing-large: calc(2 * var(--spacing-medium));
  --spacing-extra-large: calc(4 * var(--spacing-medium));
  --spacing-extra-extra-large: calc(8 * var(--spacing-medium));
  --spacing-extra-extra-extra-large: calc(16 * var(--spacing-medium));
  --border-radius-1: calc(0.125 * var(--spacing-medium));
  --border-radius-2: calc(0.25 * var(--spacing-medium));
  --border-radius-3: calc(0.5 * var(--spacing-medium));
  --border-radius-4: calc(1 * var(--spacing-medium));
  --border-radius-circle: 100%;
  --border-radius-pill: 9999px;

  --box-shadow-1: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  --box-shadow-2: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  --box-shadow-3: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  --box-shadow-4: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
  --box-shadow-5: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);

  --height-1: 16px;
  --height-2: calc(2 * var(--height-1));
  --height-3: calc(4 * var(--height-1));
  --height-4: calc(8 * var(--height-1));
  --height-5: calc(16 * var(--height-1));
  --width-1: 16px;
  --width-2: calc(2 * var(--width-1));
  --width-3: calc(4 * var(--width-1));
  --width-4: calc(8 * var(--width-1));
  --width-5: calc(16 * var(--width-1));

  --max-width-1: var(--width-1);
  --max-width-2: calc(2 * var(--width-1));
  --max-width-3: calc(4 * var(--width-1));
  --max-width-4: calc(8 * var(--width-1));
  --max-width-5: calc(16 * var(--width-1));
  --max-width-6: calc(32 * var(--width-1));
  --max-width-7: calc(48 * var(--width-1));
  --max-width-8: calc(64 * var(--width-1));
  --max-width-9: calc(96 * var(--width-1));

  --red-000: #2a1311;
  --red-100: #4d1d1c;
  --red-200: #732627;
  --red-300: #9b2e32;
  --red-400: #c5363d;
  --red-500: #d95857;
  --red-600: #e57c76;
  --red-700: #f09d97;
  --red-800: #f8beb8;
  --red-900: #fddedb;
  --gold-000: #271609;
  --gold-100: #45240f;
  --gold-200: #663212;
  --gold-300: #894213;
  --gold-400: #ad5112;
  --gold-500: #d36110;
  --gold-600: #e48043;
  --gold-700: #f0a071;
  --gold-800: #f9bf9f;
  --gold-900: #ffdfce;
  --green-000: #111c0f;
  --green-100: #193218;
  --green-200: #1f4920;
  --green-300: #246227;
  --green-400: #287b2f;
  --green-500: #2b9637;
  --green-600: #2db13e;
  --green-700: #2dcd46;
  --green-800: #2cea4e;
  --green-900: #96fc93;
  --teal-000: #121b1b;
  --teal-100: #1b302f;
  --teal-200: #234644;
  --teal-300: #2b5e5a;
  --teal-400: #337672;
  --teal-500: #3a8f8a;
  --teal-600: #41aaa3;
  --teal-700: #48c4bd;
  --teal-800: #54e0d8;
  --teal-900: #b5f0eb;
  --blue-000: #131a26;
  --blue-100: #1c2d47;
  --blue-200: #22416b;
  --blue-300: #255791;
  --blue-400: #256db9;
  --blue-500: #4884d2;
  --blue-600: #769bdb;
  --blue-700: #9bb3e5;
  --blue-800: #bdccee;
  --blue-900: #dee5f6;
  --indigo-000: #201237;
  --indigo-100: #35196c;
  --indigo-200: #491fa7;
  --indigo-300: #5d22e6;
  --indigo-400: #7d41f8;
  --indigo-500: #9962fa;
  --indigo-600: #b282fc;
  --indigo-700: #c8a1fe;
  --indigo-800: #dcc0ff;
  --indigo-900: #eedfff;
  --violet-000: #2a121d;
  --violet-100: #4e1933;
  --violet-200: #761c4b;
  --violet-300: #9f1d65;
  --violet-400: #cb187f;
  --violet-500: #dd4a95;
  --violet-600: #e873a9;
  --violet-700: #f098be;
  --violet-800: #f7bbd3;
  --violet-900: #fcdde9;
  --gray-000: #191a1a;
  --gray-100: #2c2d2d;
  --gray-200: #3f4141;
  --gray-300: #545657;
  --gray-400: #696c6d;
  --gray-500: #808385;
  --gray-600: #979b9d;
  --gray-700: #b0b3b4;
  --gray-800: #cacccd;
  --gray-900: #e4e5e6;
}
