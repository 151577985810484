<template>
  <div class="rb-guide tflex tflex-wrap titems-center">
    <div
      class="rb-guide-illustration"
      :style="{
        'background-image': 'url(' + bundleGuideIllustrationBackgroundSrc + ')',
      }"
    >
      <img
        :src="bundleGuideIllustrationSrc"
        class="rb-guide-illustration-background-image"
        width="174"
        height="174"
        loading="lazy"
        alt=""
      />
    </div>

    <div class="rb-guide-content">
      <template v-if="error">
        <h1 class="rb-guide-title tm0 tmb3">
          {{ error.title }}
        </h1>
        <p class="tm0 tmb2">
          {{ error.message }}
        </p>
      </template>
      <template v-else>
        <h1 class="rb-guide-title tm0 tmb3">Select a customizable bundle to preview this section.</h1>

        <div v-if="status === 'invalid-bundle'">
          <p class="tm0 tmb2">
            You’re currently previewing the “{{ boxConfig.product.title }}” product which is not configured as a
            customizable bundle in Recharge or is misconfigured.
          </p>
        </div>

        <p class="tm0 tmb2">
          The Bundle widget allows your customers to purchase customizable bundles from your store.
        </p>

        <p class="tm0 tmb2">Create a new bundle or select an existing one to get started.</p>

        <a class="rb-guide-btn tm0 tmt2 tpv2 tph3 tno-underline tdib" href="#" @click.prevent="createBundle"
          >Create a new bundle</a
        >
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['status', 'boxConfig', 'error'],

    computed: {
      bundleGuideIllustrationSrc() {
        return process.env.PUBLIC_URL + 'bundle-guide-illustration.png';
      },
      bundleGuideIllustrationBackgroundSrc() {
        return process.env.PUBLIC_URL + 'bundle-guide-background.png';
      },
    },
    methods: {
      createBundle() {
        if (!window.Shopify) {
          return;
        }

        const { shop } = window.Shopify;

        const [domain] = shop.split('.myshopify.com');

        const url = `https://${domain}-sp.admin.rechargeapps.com/merchant/bundles`;

        window.open(url);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .rb-guide {
    padding: 24px;
    background: linear-gradient(
      265.61deg,
      rgba(129, 205, 227, 0.08) 48.67%,
      rgba(129, 154, 227, 0.08) 72.37%,
      rgba(130, 99, 227, 0.08) 97.9%
    );

    @media screen and(min-width: 600px) {
      padding: 34px 62px;
    }

    &-illustration {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 326px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and(min-width: 600px) {
        justify-content: flex-end;
        min-height: 223px;
        padding: 34px 50px;
        background-position: 0 -9px;
      }

      img {
        height: auto;
      }
    }

    &-content {
      max-width: 530px;

      @media screen and(min-width: 600px) {
        margin-left: -10px;
      }

      p {
        font-family: inherit;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.47px;
        color: #191d48;
      }
    }

    &-title {
      font-family: inherit;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #191d48;
    }

    &-btn {
      background-color: #3901f1;
      color: #fff;
      border-radius: 2px;
      font-family: inherit;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 16px;
    }
  }
</style>
