<template>
  <div class="tflex titems-end tw-100" :style="{ flex: size }">
    <div class="tflex titems-center tjustify-between tw-100">
      <span class="tflex tflex-row">
        <span v-if="textContext" class="incentive-tier__quantity tmr1">{{ textContext }}</span>
        <span class="incentive-tier__title">{{ title }}</span>
      </span>
      <check-icon v-if="completed" class="tmr1" :size="16" />
    </div>
  </div>
</template>
<script>
  import CheckIcon from '../../CheckIcon.vue';

  export default {
    components: { CheckIcon },
    props: {
      size: { type: Number, default: 1 },
      title: { type: String, required: true },
      textContext: { type: String, default: '' },
      completed: { type: Boolean, default: false },
    },
  };
</script>
<style lang="scss" scoped>
  .incentive-tier {
    &__title,
    &__quantity {
      font-weight: var(--rb-heading-weight);
    }
  }
</style>
