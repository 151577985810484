<template>
  <div>
    <div class="rb-header tpt3-ns tpt2">
      <div
        class="rb-container rb-heading tflex tjustify-between tflex-row-l tflex-column tflex-wrap tflex-nowrap-l titems-baseline-l"
      >
        <div class="tpr4-ns tmw-100">
          <h1 class="rb-bundle-title tf1 tmb0">
            {{ title }}
          </h1>

          <p
            v-if="productSettings.description && productSettings.description.length"
            class="rb-bundle-description tf5 tmb0 tmt2"
            v-html="productSettings.description"
          />
        </div>

        <div
          v-if="showToggleFrequencySelector"
          class="rb-subscribe-and-save-container tnowrap tw-40-l tw-100 ttr-l tflex tflex-column titems-end-l tpt2 tpt0-l"
        >
          <div v-if="hasFrequencies" class="tflex titems-center tjustify-end-l">
            <span class="torder-1-l torder-2 tpointer" @click.prevent="subscribeAndSave = !subscribeAndSave">
              {{ $t('frequency.subscriptionLabel') }}
            </span>
            <label class="rb-switch tml2-l tmr0-l tmr2 tpl1 tmb0 torder-2-l torder-1">
              <input v-model="subscribeAndSave" type="checkbox" />
              <span class="rb-slider" />
            </label>
          </div>

          <div v-if="subscribeAndSave">
            <div v-if="isShopifySubscription && !subscription" class="rb-field rb-frequency tmb0 tmt2">
              <select v-if="Object.keys(activeVariant).length" v-model="sellingPlanAllocation" class="rb-select tmb0">
                <option
                  v-for="option in subscriptionSellingPlanAllocations"
                  :key="option.sellingPlan.id"
                  :value="option"
                >
                  <span>{{ option.sellingPlan.name }}</span>
                </option>
              </select>
            </div>

            <div v-else-if="subscriptionFrequencyOptions.length > 0" class="rb-field rb-frequency tmb0 tmt2">
              <select v-model="selectedFrequency" class="rb-select tmb0">
                <option v-for="option in subscriptionFrequencyOptions" :key="option" :value="option">
                  {{
                    $tc('frequency.option', Number(option), {
                      count: Number(option),
                      unit: $tc(frequencyUnitSingular, Number(option)),
                    })
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <p v-else-if="price.price !== 0" class="rb-price tf3 tlh-title ttr-l tnowrap tw-40-l tw-100">
          <Price class="tf3 tdb tfw7 tmb0" :price="price.price" :compare-at-price="price.compareAtPrice" as="span" />
        </p>
      </div>
    </div>

    <div
      ref="stickyHeader"
      class="rb-sticky-form rb-bg ttop-0 tz-3 tpt3 tflex tflex-column tmh-vh-100"
      :class="{ trelative: isStatusBarActive, tsticky: !isStatusBarActive, tmb3: !showIncentivesStatusBar }"
    >
      <div class="rb-container">
        <product-form
          class="rb-form tflex tflex-column tflex-row-ns titems-end-ns titems-start tjustify-between tpb2-ns tmb3"
          :product="product"
          :price="price"
          :show-frequency-selector="!showToggleFrequencySelector"
          @frequency-changed="selectedFrequency = $event"
        >
          <div class="rb-btn-wrapper tdb tw-100 tw-auto-ns trelative">
            <button v-if="product.available && !isStatusBarActive" class="rb-btn tnowrap" @click.prevent="next">
              <span v-if="flowCompleted">{{ $t('addToCart.added') }}</span>
              <span v-else-if="selectionIsBalanced">{{ $t('addToCart.completed') }}</span>
              <notice v-else />
            </button>

            <button
              v-else-if="isStatusBarActive"
              class="rb-btn tnowrap"
              :disabled="!selectionIsBalanced"
              @click.prevent="next"
            >
              {{ $t('addToCart.completed') }}
            </button>

            <button v-else class="rb-btn tnowrap tdisabled" disabled>
              {{ $t('addToCart.soldOut') }}
            </button>

            <info-message
              v-if="!isStatusBarActive"
              :message="alertMessage"
              :class-names="'tright-0-ns ttop-1'"
              :position="'right'"
              :dismissible="true"
              :show="showAlert"
              @hide="hideAlert"
            />
          </div>
        </product-form>

        <progress-bar-selection v-if="!hasDynamicRanges && !showIncentivesStatusBar" />
      </div>
    </div>

    <div v-if="showIncentivesStatusBar" class="rb-container tmb3">
      <status-bar-discounts-incentive />
    </div>

    <builder class="rb-box-builder rb-container" :sticky-header-height="stickyHeaderHeight" />

    <addons v-if="addonsEnabled" class="rb-container">
      <div class="rb-addons-header tmb4 tbt tpt4">
        <h1 class="rb-addons-title tf1 tmt0 tmb2">
          {{ $t('addOns.title') }}
        </h1>
        <p class="rb-addons-description tf5 tmb3">
          {{ $t('addOns.description') }}
        </p>
      </div>
    </addons>

    <badge v-if="layoutSettings.egdab" class="rb-container" />
  </div>
  <status-bar-sign-up v-if="isStatusBarActive" :price="price">
    <div class="rb-form tflex tflex-column tflex-row-ns titems-end-ns titems-start tjustify-between">
      <button v-if="product.available" class="rb-btn tnowrap" :disabled="!selectionIsBalanced" @click.prevent="next">
        {{ $t('addToCart.completed') }}
      </button>

      <button v-else class="rb-btn tnowrap tdisabled" disabled>
        {{ $t('addToCart.soldOut') }}
      </button>
    </div>
  </status-bar-sign-up>
</template>

<script>
  import ProductForm from './ProductForm';
  import InfoMessage from './InfoMessage';
  import Notice from './Notice';
  import Builder from './BoxBuilder';
  import Addons from './Addons';
  import Badge from './Badge';
  import Price from './Price';
  import StatusBarSignUp from './StatusBar/StatusBarSignUp.vue';
  import ProgressBarSelection from './ProgressBarSelection.vue';
  import StatusBarDiscountsIncentive from './StatusBar/discounts/StatusBarDiscountsIncentive.vue';

  import { mapState, mapGetters } from 'vuex';

  export default {
    components: {
      Addons,
      Badge,
      Builder,
      InfoMessage,
      Notice,
      Price,
      ProductForm,
      ProgressBarSelection,
      StatusBarSignUp,
      StatusBarDiscountsIncentive,
    },

    props: ['price'],
    emits: ['add-to-cart'],
    data() {
      return {
        stickyHeaderHeight: 0,
        showAlert: false,
        alertMessage: '',
        alertTimeout: null,
      };
    },

    computed: {
      ...mapState({
        product: (state) => state.product,
        subscription: (state) => state.subscription.subscription,
        selectedFrequency: (state) => state.selectedFrequency,
        selectedSellingPlanAllocation: (state) => state.selectedSellingPlanAllocation,
        flowCompleted: (state) => state.flowCompleted,
      }),

      ...mapGetters([
        'showIncentivesStatusBar',
        'productSettings',
        'activeVariant',
        'frequencyUnitSingular',
        'productSettings',
        'selectedContentsCount',
        'selectionIsBalanced',
        'dataSourceState',
        'allDataSourceRequired',
        'dataSources',
        'isShopifySubscription',
        'layoutSettings',
        'frequencyOptions',
        'sellingPlanAllocations',
        'oneTimePlanAllocation',
        'addonsEnabled',
        'oneTimeAllowed',
        'remainder',
        'hasDynamicRanges',
        'showStickyStatusbar',
      ]),

      templateSettings() {
        return this.layoutSettings?.templateSettings?.onePage ?? {};
      },

      showToggleFrequencySelector() {
        const hasSubscriptionOptions = this.isShopifySubscription || this.subscriptionFrequencyOptions.length;

        // Toggle is only available if both one time and subscription is available
        if (!(this.oneTimeAllowed && hasSubscriptionOptions)) {
          return false;
        }
        if (this.templateSettings.frequencySelector === 'toggle') {
          return true;
        }
        return this.layoutSettings?.subscribeAndSave ?? false;
      },

      subscriptionSellingPlanAllocations() {
        return this.sellingPlanAllocations.filter((allocation) => Number(allocation.sellingPlan.id) > 0);
      },

      subscriptionFrequencyOptions() {
        return this.frequencyOptions.filter((option) => Number(option) > 0);
      },

      title() {
        if (this.productSettings.title && this.productSettings.title.length) {
          return this.productSettings.title;
        } else {
          return this.product.title;
        }
      },

      selectedFrequency: {
        get() {
          return this.$store.state.selectedFrequency;
        },
        set(value) {
          this.$store.commit('updateSelectedFrequency', value);
        },
      },

      sellingPlanAllocation: {
        get() {
          return this.$store.state.selectedSellingPlanAllocation;
        },
        set(value) {
          this.$store.commit('updateSelectedSellingPlanAllocation', value);
        },
      },

      hasFrequencies() {
        return !!this.selectedFrequency || !!this.selectedSellingPlanAllocation;
      },

      subscribeAndSave: {
        get() {
          if (this.sellingPlanAllocation) {
            return this.sellingPlanAllocation.sellingPlan.id !== 0;
          } else {
            return Number.parseInt(this.selectedFrequency) > 0;
          }
        },
        set(value) {
          if (this.sellingPlanAllocation) {
            if (value) {
              let allocation = this.subscriptionSellingPlanAllocations[0];
              if (this.productSettings.defaultFrequency && this.productSettings.defaultFrequency !== '0') {
                const sellingPlanId = Number(this.productSettings.defaultFrequency);
                allocation = this.sellingPlanAllocations.find((s) => s.sellingPlan.id === sellingPlanId) ?? allocation;
              }
              this.sellingPlanAllocation = allocation;
            } else {
              this.sellingPlanAllocation = this.oneTimePlanAllocation;
            }
          } else {
            if (value) {
              if (typeof this.productSettings.defaultFrequency && this.productSettings.defaultFrequency !== '0') {
                this.selectedFrequency = this.productSettings.defaultFrequency;
              } else {
                this.selectedFrequency = this.subscriptionFrequencyOptions[0];
              }
            } else {
              this.selectedFrequency = '0';
            }
          }
        },
      },

      isStatusBarActive() {
        return this.showStickyStatusbar;
      },
    },

    watch: {
      activeVariant: function (oldVariant, newVariant) {
        if (oldVariant.id !== newVariant.id) {
          if (this.selectedContentsCount === 0) {
            return;
          }
          let updateRequired = false;
          for (let i = 0; i < this.dataSourceState.length; i++) {
            if (this.dataSourceState[i][1] === 0) {
              updateRequired = true;
              break;
            }
          }
          if (updateRequired) {
            let msg = this.$t('alert.planChanged');
            this.setAlert(msg);
          }
        }
      },

      // show alert messages when all data sources are bounded and completed
      dataSourceState: function (newState, preState) {
        if (this.allDataSourceRequired) {
          let done = 1;
          let changed = -1;
          let pending = -1;
          for (let i = 0; i < newState.length; i++) {
            done = done * newState[i][1]; // this help us identifiy if we are done or there are still collections pending
            if (newState[i][1] === 1 && preState[i][1] === 0) {
              changed = i;
            } else if (newState[i][1] === 0 && pending < 0) {
              pending = i;
            }

            if (done === 0 && changed >= 0) {
              break;
            }
          }
          let msg;
          if (done === 1) {
            msg = this.$t('alert.complete');
            this.setAlert(msg);
          } else if (changed >= 0 && newState[changed][3] === preState[changed][3]) {
            // show the alert only if it was completed and it was inside the same plan, otherwise the user might be changing plans and finishing in a completed state

            let completeCollection = this.dataSources[changed].title;
            let pendingCollection = this.dataSources[pending].title;
            let remainder = Math.abs(newState[pending][2]);
            let remainderObject = { remainder, collectionTitle: pendingCollection };
            let remainderMsg =
              newState[pending][2] > 0
                ? this.$t('alert.add', remainderObject)
                : this.$t('alert.remove', remainderObject);

            let msg = this.$t('alert.collectionComplete', { collectionTitle: completeCollection }) + ' ' + remainderMsg;

            this.setAlert(msg);
          }
        }
      },
    },

    mounted() {
      if (this.$refs.stickyHeader && !this.isStatusBarActive) {
        this.stickyHeaderHeight = this.$refs.stickyHeader.clientHeight - 1;
      }
    },

    beforeUnmount() {
      if (this.alertTimeout) clearTimeout(this.alertTimeout);
    },

    methods: {
      // This is used when user clicks on disabled button
      showState() {
        let msg;

        if (this.allDataSourceRequired) {
          //dataSourceState [collectionId, pending, left, activeVariantId, index]
          let firstPendingDataSourceState = this.dataSourceState.find((dsState) => {
            return dsState[1] === 0;
          });

          let DSIndex = firstPendingDataSourceState[4];
          let itemsLeft = firstPendingDataSourceState[2];
          let remainder = Math.abs(itemsLeft);
          let collectionTitle = this.dataSources[DSIndex].title;
          let remainderObject = { remainder, collectionTitle };
          msg = itemsLeft > 0 ? this.$t('alert.add', remainderObject) : this.$t('alert.remove', remainderObject);
        } else {
          const remainder = this.remainder;

          if (remainder > 0) {
            msg = this.$t('addToCart.add', { remainder });
          } else if (remainder < 0) {
            msg = this.$t('addToCart.remove', { remainder: Math.abs(remainder) });
          } else {
            msg = this.$t('addToCart.updateSelections');
          }
        }

        this.setAlert(msg);
      },

      setAlert(message) {
        if (this.alertTimeout) clearTimeout(this.alertTimeout);

        this.alertMessage = message;
        this.showAlert = true;
        this.alertTimeout = setTimeout(() => {
          this.hideAlert();
        }, 3000);
      },

      hideAlert() {
        this.showAlert = false;
        this.alertMessage = '';
      },

      next() {
        if (!this.selectionIsBalanced) {
          return this.showState();
        }
        this.$emit('add-to-cart');
      },
    },
  };
</script>

<style>
  /* ###Subscribe and Save### */
  /* The switch - the box around the slider */
  .rb-switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .rb-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .rb-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 24px;
  }
  .rb-slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 50%;
  }
  input:checked + .rb-slider {
    background-color: var(--rb-btn-bg);
  }
  input:focus + .rb-slider {
    box-shadow: 0 0 1px var(--rb-btn-bg);
  }
  input:checked + .rb-slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
</style>
