<template>
  <VTooltip :shown="show" :placement="position" :auto-hide="false" :triggers="[]" theme="info-tooltip">
    <slot />

    <template #popper="{ hide }">
      <i v-if="dismissible" role="button" class="tfixed dismiss-button ttrnr1 ttnt2 tfs-normal tlh-solid" @click="hide">
        &times;
      </i>
      {{ message }}
    </template>
  </VTooltip>
</template>

<script>
  import 'floating-vue/dist/style.css';

  export default {
    props: {
      message: String,

      // We're keeping most of the props from the original InfoMessage
      show: Boolean,
      position: String,
      dismissible: Boolean,
    },
  };
</script>

<style>
  /* We want to prevent the tooltip overlaps the status bar */
  .v-popper__popper {
    z-index: 2;
  }
  .v-popper--theme-info-tooltip .v-popper__inner {
    background: var(--rb-alerts-bg);
    box-shadow: 1px 5px 10px -2px rgba(0, 0, 0, 0.2);
    color: var(--rb-alerts-color);
    padding: 1rem 1.5rem;
  }

  .dismiss-button {
    top: 0.1rem;
    right: 0.25rem;
    cursor: pointer;
  }
</style>
