<template>
  <div role="status" class="status-bar tflex-row tjustify-between titems-center">
    <div class="tflex tflex-column">
      <status-bar-range-message class="rb-notice rb-portal-heading tf5" :price="price" />
      <status-bar-selection-message class="tf6" :price="price" />
    </div>
    <div
      class="rb-portal-actions tflex tflex-row titems-center tjustify-between tjustify-start-l tmt0-l"
      :class="{ tmt3: addMarginToButtons }"
    >
      <!-- It does not use a button (semantic HTML) to avoid Shopify overwrites the button styles -->
      <a
        v-if="!hideCancelButton"
        class="rb-btn-cancel tlink rb-portal-link tmr3"
        href="#"
        role="button"
        @click.prevent="$emit('cancel')"
      >
        {{ $t('cancel') }}
      </a>
      <button-primary :disabled="!canSave || busy" :is-loading="isLoading" @click.prevent="$emit('save')">
        {{ textSaveButton || $t('subscriptionPage.save') }}
      </button-primary>
    </div>
  </div>
</template>
<script>
  // @ts-check
  import { mapState, mapGetters } from 'vuex';

  import ButtonPrimary from '../Buttons/ButtonPrimary.vue';
  import StatusBarRangeMessage from './StatusBarRangeMessage.vue';
  import StatusBarSelectionMessage from './StatusBarSelectionMessage.vue';

  export default {
    components: { ButtonPrimary, StatusBarRangeMessage, StatusBarSelectionMessage },
    props: {
      hideCancelButton: { type: Boolean, required: false },
      price: { type: Object, required: true },
      textSaveButton: { type: String, required: true },
      addMarginToButtons: { type: Boolean, default: true },
    },
    emits: ['cancel', 'save'],
    computed: {
      ...mapState({
        busy: (state) => state.busy,
        isLoading: (state) => state.isLoading,
      }),
      ...mapGetters(['canSave']),
    },
  };
</script>
<style lang="scss" scoped>
  .status-bar {
    &--border {
      box-shadow:
        0px 2px 8px rgba(0, 0, 0, 0.15),
        0px 2px 20px rgba(0, 0, 0, 0.15);
      min-height: 90px;
      padding-left: var(--spacing-medium);
      padding-right: var(--spacing-medium);
    }
  }
</style>
