<template>
  <div class="rb-spinner">
    <div class="rb-bounce rb-bounce1" />
    <div class="rb-bounce rb-bounce2" />
    <div class="rb-bounce rb-bounce3" />
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .rb-bounce {
    background-color: var(--rb-btn-bg);
  }
  .rb-spinner {
    display: flex;
    margin: 0;
    width: 70px;
    text-align: center;
  }
  .rb-spinner > div {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .rb-spinner .rb-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .rb-spinner .rb-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
