<template>
  <span class="tlh-title tlh-title--font-weight mb0">{{ message }}</span>
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      price: { type: Object, required: true },
    },
    computed: {
      ...mapGetters([
        'getDynamicRangesBounds',
        'boxItemCount',
        'selectedContentsCount',
        'remainder',
        'dynamicRangeCountDifference',
      ]),

      prices: function () {
        const hascomparePrice = this.price.compareAtPrice > this.price.price;
        const price = this.$n(this.price.price, 'currency');
        const compareAtPrice = hascomparePrice ? this.$n(this.price.compareAtPrice, 'currency') : '';

        return { price, compareAtPrice };
      },

      message: function () {
        // For non dynamic bundkles we show the number of items in the box
        if (this.boxItemCount) {
          return this.$t('remainder.statusAdd', {
            min: this.boxItemCount,
            max: this.boxItemCount,
            remainder: this.remainder,
            selected: this.selectedContentsCount,
            price: this.prices.price,
            compareAtPrice: this.price.compareAtPrice,
          });
        }

        const { min, max } = this.getDynamicRangesBounds;
        // By default we show a message: Choose at least {min}
        let messageKey = 'remainder.statusChooseAtLeast';

        // For dynamic bundle ranges are min == max, the bundled is handle as fixed and we show a message: Choose {remainder}
        if (min === max) {
          messageKey = 'remainder.statusAdd';
        } else if (Number.isFinite(max)) {
          // If the dynamic range have max limit (max != null) we show a message referring to the dynamic range: Choose {min} - {max}
          messageKey = 'remainder.statusAddRange';
        } else if (min === 1) {
          // If there is only one product required (min ==1) there is not max value (max == null). We show a message: Choose as many as you like
          messageKey = 'alert.dynamicRangeChooseAsManyAsYouLike';
        }

        return this.$t(messageKey, {
          min,
          max,
          selected: this.selectedContentsCount,
          remainder: Math.max(this.dynamicRangeCountDifference, 0),
          price: this.prices.price,
          compareAtPrice: this.price.compareAtPrice,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and(min-width: 600px) {
    .tlh-title {
      &--font-weight {
        font-weight: var(--rb-heading-weight);
      }
    }
  }
</style>
