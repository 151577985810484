<template>
  <transition name="fade">
    <div v-if="show" class="trelative" :style="chevronPosition">
      <div class="rb-alert tz-3 tabsolute tpa3" :class="classNames" @click.prevent="hide">
        <div class="trelative tpr3">
          {{ message }}
          <i v-if="dismissible" class="tabsolute ttop-0 tright-0 ttnr1 ttnt2 tlh-solid tfs-normal">×</i>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      show: Boolean,
      classNames: String,
      message: String,
      dismissible: Boolean,
      position: String,
    },
    data() {
      return {};
    },

    computed: {
      chevronPosition() {
        let left = 'calc(50% - .75rem)';
        let right = 'unset';

        if (this.position === 'left') {
          left = '3rem';
        } else if (this.position === 'right') {
          left = 'unset';
          right = '3rem';
        }

        return {
          '--rb-alert-position-left': left,
          '--rb-alert-position-right': right,
        };
      },
    },

    methods: {
      hide() {
        if (this.dismissible) {
          this.$emit('hide');
        }
      },
    },
  };
</script>

<style>
  .rb-alert {
    box-shadow: 1px 5px 10px -2px rgba(0, 0, 0, 0.2);
    width: 100%;
  }
  .rb-alert:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.6rem 0.5rem 0.6rem;
    position: absolute;
    top: 0;
    left: calc(50% - 0.75rem);
    margin-right: -0.6rem;
    margin-top: -0.5rem;
  }
  @media screen and (min-width: 48em) {
    .rb-alert {
      width: 350px;
    }
    .rb-alert:before {
      margin-right: 0;
      left: var(--rb-alert-position-left);
      right: var(--rb-alert-position-right);
    }
  }
</style>
