<template>
  <!-- It does not use a button (semantic HTML) to avoid Shopify overwrites the button styles -->
  <a :class="{ tdisabled: disabled }" class="rb-btn rb-subs-nav-btn tnowrap" href="#" role="button">
    <span :class="{ 'rb-subs-nav-btn-text': isLoading }"><slot /></span>
    <spinner-icon :class="{ 'rb-subs-nav-btn-icon-loading': isLoading, 'rb-subs-nav-btn-icon': !isLoading }" />
  </a>
</template>
<script>
  // @ts-check
  import SpinnerIcon from '../SpinnerIcon.vue';

  export default {
    components: { SpinnerIcon },
    props: {
      isLoading: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },
  };
</script>
<style lang="scss" scoped>
  // Create a new class to show/hide the spinner icon keeping the button's width according to the content
  .rb-subs-nav-btn {
    align-items: center;
    display: flex;
    font-family: inherit;
    justify-content: center;
    letter-spacing: inherit;
    position: relative;

    &-icon {
      display: none;

      &-loading {
        display: block;
        pointer-events: none;
        position: absolute;
      }
    }

    &-text {
      visibility: hidden;
    }
  }
</style>
