<template>
  <div aria-busy="true">
    <div class="rb-skeleton tpa3 w-100 tmb4" aria-hidden="true">loading...</div>

    <div class="rb-container">
      <div class="rb-step-wrapper tmb4">
        <div class="rb-step-header rb-skeleton tpa4" aria-hidden="true">loading...</div>

        <div class="rb-options-wrapper tjustify-center tflex tjustify-start">
          <div
            v-for="(option, i) in firstOptionValuesCount"
            :key="option"
            class="rb-option tw-20-ns tw-100 tpa3 rb-skeleton rb-skeleton-multi-step-option db-ns"
            :class="{ dn: i > 0 }"
            aria-hidden="true"
          >
            loading...
          </div>
        </div>

        <div class="ttc tpt4">
          <span class="rb-skeleton tpa3" aria-hidden="true">loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['variants'],
    computed: {
      firstOptionValuesCount() {
        return [...new Set(this.variants.map((v) => v.option1))].length;
      },
    },
  };
</script>

<style scoped>
  .rb-skeleton-multi-step-option {
    height: 300px;
  }
</style>
