<template>
  <div class="tflex tflex-column titems-end" :style="{ flex: size }">
    <span v-if="textContext" class="incentive-tier__quantity tflex tjustify-end titems-center">
      <check-icon v-if="completed" class="tmr1" :size="16" />
      {{ textContext }}
    </span>
    <span class="tflex incentive-tier__title tmb2">
      {{ title }}
    </span>
  </div>
</template>
<script>
  import CheckIcon from '../../CheckIcon.vue';

  export default {
    components: { CheckIcon },
    props: {
      size: { type: Number, default: 1 },
      title: { type: String, required: true },
      textContext: { type: String, default: '' },
      completed: { type: Boolean, default: false },
    },
  };
</script>
<style lang="scss" scoped>
  .incentive-tier {
    &__title,
    &__quantity {
      font-weight: var(--rb-heading-weight);
    }
  }
</style>
