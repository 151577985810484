<template>
  <modal :container-classes="'rb-bg th-100 tw900 tflex tflex-column toverflow-hidden'" @close="$emit('close')">
    <div class="tmw-100 tmh-100 minh-100 toverflow-y-auto">
      <div class="tpa4">
        <h1 class="rb-modal-title tf2 tmb3 tmh0" tabindex="0">
          {{ activeProduct.title }}
        </h1>
        <div class="tflex tflex-row-ns tflex-column titems-start tnl3-ns tnr3-ns">
          <div
            class="rb-modal-images-wrapper tw-40-ns tw-100 tmh-100 tflex tflex-column titems-center tph3-ns tmb3 tmb0-ns"
          >
            <div v-if="activeProduct.images.length > 1" id="rb-modal-images-carousel" class="tw-100">
              <vueper-slides
                class="rb-slides no-shadow tw-100"
                :breakpoints="breakpoints"
                :slide-ratio="1"
                :bullets="false"
              >
                <vueper-slide v-for="(image, i) in activeProduct.images" :key="i">
                  <template #content>
                    <div class="rb-modal-image-container tflex titems-center tjustify-center tw-100 th-100">
                      <img
                        :src="image"
                        class="rb-modal-image tmw-100 tmh-100-ns"
                        :alt="activeProduct.title"
                        loading="lazy"
                      />
                    </div>
                  </template>
                </vueper-slide>
              </vueper-slides>
            </div>

            <div v-else class="tw-100 tflex titems-center">
              <img
                :src="activeProduct.images[0]"
                class="rb-modal-images-static tmw-100 tmh-100"
                :alt="activeProduct.title"
                loading="lazy"
              />
            </div>
          </div>

          <div class="rb-modal-info tw-60-ns tw-100 tmh-100 tph3-ns">
            <p tabindex="0" class="rb-modal-description pa0 mt0 mb3 tpb5" v-html="activeProduct.description" />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { VueperSlides, VueperSlide } from 'vueperslides';
  import 'vueperslides/dist/vueperslides.css';

  import Modal from './Modal';

  export default {
    components: { VueperSlides, VueperSlide, Modal },

    props: ['activeProduct'],
    emits: ['close'],
  };
</script>

<style>
  .vueperslides__arrow {
    padding: 0.5rem;
  }
  .vueperslides__arrow svg {
    padding: 0;
    width: 1rem;
  }
</style>
