<template>
  <div
    role="status"
    class="status-bar-sign-up rb-container rb-sticky-container tz-3 tw-100 tfixed-s tsticky tbottom-0 tbottom-2-l tleft-0 tleft-auto"
  >
    <div class="rb-bg rb-sticky-content tflex tflex-row tflex-wrap tjustify-between titems-center">
      <div class="tflex tflex-column">
        <status-bar-range-message class="rb-notice rb-portal-heading tf5 tf3-l" :price="price" />
        <status-bar-selection-message class="tf6 tf5-l" :price="price" />
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
  // @ts-check
  import StatusBarRangeMessage from './StatusBarRangeMessage.vue';
  import StatusBarSelectionMessage from './StatusBarSelectionMessage.vue';

  export default {
    components: { StatusBarRangeMessage, StatusBarSelectionMessage },
    props: {
      price: { type: Object, required: true },
    },
  };
</script>
<style lang="scss" scoped>
  .rb-sticky {
    &-container {
      min-height: 90px;
      padding: 0;
    }

    &-content {
      border-radius: var(--rb-item-radius);
      box-shadow:
        0px 2px 8px rgba(0, 0, 0, 0.15),
        0px 2px 20px rgba(0, 0, 0, 0.15);
      min-height: 90px;
      padding-left: var(--spacing-large);
      padding-right: var(--spacing-large);
    }
  }

  @media screen and (max-width: 600px) {
    .tleft-0 {
      left: 0;
    }

    .tfixed-s {
      position: fixed;
    }
  }

  @media screen and(min-width: 600px) {
    .rb-sticky {
      &-content {
        padding-left: var(--spacing-medium);
        padding-right: var(--spacing-medium);
      }
    }
    .tleft-auto {
      left: auto;
    }
  }
</style>
