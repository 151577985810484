<template>
  <div class="rb-progress-bar-wrapper th-auto" style="width: 10px">
    <div
      class="trelative toverflow-hidden th-100 tw-100 rb-progress-bar-guide"
      :class="{
        'progress-bar__width--small': width === 'small',
        'progress-bar__width--medium': width === 'medium',
      }"
    >
      <div
        role="progressbar"
        :aria-label="ariaLabelText"
        :aria-valuenow="(value * 100) / maxValue"
        class="rb-progress-bar-guide tabsolute th-100 tw-100 tbr3 tz-1"
      />
      <div
        class="rb-progress-bar tabsolute th-100 tw-100 tbr3 tz-1"
        :style="{ bottom: quotientTop + '%', transition: 'bottom ease-in-out 350ms' }"
        :data-rb-added="value"
        :data-rb-left="maxValue - value"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      ariaLabelText: { type: String, required: true },
      value: { type: Number, required: true },
      maxValue: { type: Number, required: true },
      size: { type: String, default: 'medium' },
    },
    computed: {
      quotientTop() {
        const quotientTop = (1 - this.value / this.maxValue) * 100;

        return Math.max(quotientTop, 0);
      },
      width() {
        if (['small', 'medium'].includes(this.size)) {
          return this.size;
        }

        return 'medium';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .progress-bar {
    min-height: var(--height-1);

    &__width {
      &--small {
        width: calc(var(--width-1) * 0.4);
      }
      &--medium {
        width: var(--width-1);
      }
    }
  }
</style>
