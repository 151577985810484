<template>
  <div class="rb-review-order tflex tflex-row-ns tflex-column titems-start tw1000 tcenter">
    <div class="rb-order tph4-ns tw-60-ns tw-100">
      <div class="rb-order-box-group rb-order-group tflex tflex-column ttl tmb4">
        <h3 class="rb-order-box-product rb-order-group-title tf3 tmh0 tmb2">
          {{ product.title }}
        </h3>

        <div class="rb-order-box-info tflex tjustify-between tw-100">
          <div class="rb-order-box-details tw-100">
            <p v-if="activeVariant.title != 'Default Title'" class="rb-order-box-variant rb-order-item-title tfw7">
              {{ activeVariant.title }}
            </p>

            <Price
              v-if="!isDynamicBundle"
              class="rb-order-box-price rb-order-item-price"
              :price="bundleFixedPrice.price"
              :compare-at-price="bundleFixedPrice.compareAtPrice"
              as="p"
            />

            <div class="rb-box-contents">
              <div
                v-for="dataSource in dataSources"
                v-show="getSelectedOnly(dataSource).length"
                :key="dataSource.id"
                class="rb-box-contents-group tflex tflex-column ttl"
              >
                <h4 class="rb-box-contents-title tf5">
                  {{ dataSource.title }}
                </h4>

                <ul class="rb-box-contents-list tph0 tma0 tflex tflex-column tmb0">
                  <li
                    v-for="item in getSelectedOnly(dataSource)"
                    :key="item.id"
                    class="rb-box-item tinline-flex tmb2 tw-100"
                  >
                    <div class="rb-box-item-img tw3 tmr3">
                      <div class="rb-aspect-ratio taspect-ratio taspect-ratio--1x1">
                        <div
                          class="rb-img rb-bg taspect-ratio--object toverflow-hidden tz-1 tflex titems-center tjustify-center"
                        >
                          <img
                            :src="resizeImage(item.featured_image, 200)"
                            class="tw-100 tdb"
                            :alt="item.title"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>

                    <BoxSummaryItem :item="item" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <Price v-if="!isDynamicBundle" class="rb-order-line-price tmb0" :price="boxLinePrice" as="p" />
        </div>
      </div>

      <!-- Addons -->
      <div
        v-if="Object.keys(addonsOrderFiltered).length"
        class="rb-order-addons-group rb-order-group tflex tflex-column ttl tmb4"
      >
        <h3 class="rb-order-addons-title rb-order-group-title tf3 tmb2">
          {{ $t('multiStep.summaryStep.addons') }}
        </h3>

        <ul class="rb-order-items-list tph0 tma0 tflex tflex-column">
          <addon-order-line
            v-for="product in addonsList"
            :key="product.id"
            :product="product"
            :order="addonsOrderFiltered[product.id]"
          />
        </ul>
      </div>

      <div>
        <div class="rb-order-totals tflex tjustify-between titems-baseline">
          <h3 class="rb-order-total-label tf3">
            {{ $t('multiStep.summaryStep.total') }}
          </h3>
          <Price class="tf3" :price="orderTotalPrice" as="p" />
        </div>
      </div>

      <div v-if="showIncentivesStatusBar && tierDiscountToApply()" class="rb-incentive-tier__message tpa3 ttl">
        <i18n-t keypath="incentives.discountTierSummaryMessage">
          <template #discountMsj>
            <span class="rb-incentive-tier__message--bold">
              {{ $t('incentives.discountTierExtraOff', { discount: tierDiscount }) }}
            </span>
          </template>
        </i18n-t>
      </div>
    </div>

    <div class="rb-frequency-wrapper tph4-ns tw-40-ns tw-100 ttl">
      <h4 class="rb-frequency-title tf3">
        {{ $t('frequency.label') }}
      </h4>

      <frequency-selector />
    </div>
  </div>
</template>

<script>
  import FrequencySelector from './FrequencySelector.vue';
  import AddonOrderLine from './AddonOrderLine.vue';
  import Price from '../Price.vue';
  import BoxSummaryItem from './BoxSummaryItem.vue';
  import { mapState, mapGetters } from 'vuex';
  import { formatDiscountValue } from '../../utils/discounts';

  export default {
    components: { FrequencySelector, AddonOrderLine, BoxSummaryItem, Price },

    computed: {
      ...mapState({
        sources: (state) => state.sources,
        product: (state) => state.product,
        selectedContents: (state) => state.selectedContents,
      }),

      ...mapGetters([
        'tierDiscountToApply',
        'showIncentivesStatusBar',
        'activeVariant',
        'addonProducts',
        'addonsOrderFiltered',
        'bundleFixedPrice',
        'dataSources',
        'getDataSourceCollection',
        'isDynamicBundle',
        'orderTotals',
        'resizeImage',
        'variantActivePrice',
      ]),

      addonsList() {
        return this.addonProducts.filter((product) => {
          return this.addonsOrderFiltered.hasOwnProperty(product.id);
        });
      },

      boxLinePrice() {
        return this.variantActivePrice(this.product, this.activeVariant);
      },

      orderTotalPrice() {
        return this.orderTotals.active;
      },

      tierDiscount() {
        const tierDiscountToApply = this.tierDiscountToApply();
        if (tierDiscountToApply) {
          return formatDiscountValue(tierDiscountToApply.discountType, tierDiscountToApply.discountValue, this.$n);
        }
        return '';
      },
    },

    methods: {
      getSelectedOnly(dataSource) {
        const products = this.sources[this.activeVariant.id][dataSource.collectionId];

        return products.filter((product) => this.selectedContents[product.id]?.qty > 0);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .incentive-tier {
    &__message {
      border-color: var(--rb-option-border-selected);
    }
  }
</style>
