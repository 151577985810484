<template>
  <div aria-label="loading" aria-busy="true" class="rb-spinner-root">
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" class="rb-spinner-icon">
      <path d="M14.807 4.506a8 8 0 1 0 4.291 3.8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  </div>
</template>
<style lang="scss" scoped>
  .rb-spinner {
    &-icon {
      animation-name: rotate;
      animation-timing-function: linear;
      animation-duration: 900ms;
      animation-iteration-count: infinite;
      transition: 'opacity 300ms';
      line-height: 0;
    }

    &-root {
      height: 24px;
      overflow: hidden;
      position: relative;
      width: 24px;
    }
  }

  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
