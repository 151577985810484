<template>
  <svg :width="size" :height="size" viewBox="0 0 512 512" class="check-icon--svg" xmlns="http://www.w3.org/2000/svg">
    <path
      class="check-icon--path"
      stroke="#FFF"
      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      size: { type: Number, default: 24 },
    },
  };
</script>
<style lang="scss" scoped>
  .check-icon {
    &--svg {
      background-color: var(--rb-btn-color);
      border-radius: 100%;
    }

    &--path {
      fill: var(--rb-btn-bg);
    }
  }
</style>
