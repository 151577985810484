<template>
  <div class="tflex-l tdn">
    <status-bar-discounts-tier-desktop
      v-for="(tierDiscount, index) in tierDiscounts"
      :key="tierDiscount.discountValue"
      :title="getTitle(tierDiscount)"
      :size="getTierContainerSize(tierDiscount, index)"
      :completed="tierDiscount.quantity.value <= selectedContentsCount"
      :text-context="$t('incentives.discountTierMessage', { quantity: tierDiscount.quantity.value })"
    />
  </div>

  <div class="tflex-l tdn">
    <div v-for="(bar, index) in progressBars" :key="index" class="tmr1" :style="{ flex: getBarSize(index) }">
      <progress-bar size="small" v-bind="getTierDiscountProgress(index)" />
    </div>
  </div>
</template>

<script>
  const MAX_PRODUCT_BARS = 15;

  import { mapGetters } from 'vuex';
  import ProgressBar from '../../ProgressBar.vue';
  import { formatDiscountValue } from '../../../utils/discounts';
  import StatusBarDiscountsTierDesktop from './StatusBarDiscountsTierDesktop.vue';

  export default {
    components: { ProgressBar, StatusBarDiscountsTierDesktop },
    computed: {
      ...mapGetters(['selectedContentsCount', 'tierDiscountsIncentive']),
      progressBars() {
        const maxQuantity = this.maxTierQuantity;
        const bars = maxQuantity > MAX_PRODUCT_BARS ? this.tierDiscounts.length : maxQuantity;
        return new Array(bars).fill(0);
      },
      maxTierQuantity() {
        return this.tierDiscounts[this.tierDiscounts.length - 1]?.quantity.value ?? 0;
      },

      tierDiscounts() {
        return this.tierDiscountsIncentive();
      },
    },
    methods: {
      getTitle(tierDiscount) {
        return this.$t('incentives.discountTierTitle', {
          discount: formatDiscountValue(tierDiscount.discountType, tierDiscount.discountValue, this.$n),
        });
      },

      getTierDiscountProgress(index) {
        if (this.maxTierQuantity <= MAX_PRODUCT_BARS) {
          return { value: index < this.selectedContentsCount ? 1 : 0, maxValue: 1 };
        }

        const tierDiscount = this.tierDiscounts[index];
        const previousTierDiscount = this.tierDiscounts[index - 1];
        return {
          value: this.selectedContentsCount - (previousTierDiscount?.quantity.value ?? 0),
          maxValue: tierDiscount.quantity.value - (previousTierDiscount?.quantity.value ?? 0),
        };
      },

      isTierDiscountCompleted(tierDiscount) {
        return tierDiscount.quantity.value <= this.selectedContentsCount;
      },

      getBarSize(tierIndex) {
        if (this.maxTierQuantity <= MAX_PRODUCT_BARS) {
          return 1;
        }

        return this.getTierDiscountProgress(tierIndex).maxValue;
      },

      getTierContainerSize(tierDiscount, index) {
        const previousTierDiscount = this.tierDiscounts[index - 1];
        return tierDiscount.quantity.value - (previousTierDiscount?.quantity.value ?? 0);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .rb-incentives-status {
    &__container {
      border-radius: var(--rb-item-radius);
      min-height: 95px;
      padding: var(--spacing-medium);
    }

    &__title {
      font-weight: var(--rb-heading-weight);
    }
  }
</style>
